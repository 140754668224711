import {createContext} from 'react';
import {action, observable, makeObservable} from 'mobx';
import {OrderBundle, OrderProduct, User} from '../types';
import api from '../api/api';

class UserStore {
  user: User | null = null;
  favorites: number[] = [];
  loading: boolean = false;
  bundlesCart: OrderBundle[] = [];
  token: string | null = null;
  cart: OrderProduct[] = [];
  bonus = 0;

  constructor() {
    makeObservable(this, {
      token: observable,
      user: observable,
      loading: observable,
      favorites: observable,
      cart: observable,
      bonus: observable,
      bundlesCart: observable,
      setLoading: action.bound,
      setToken: action.bound,
      setUser: action.bound,
      setFavorites: action.bound,
      setCart: action.bound,
      setBundlesCart: action.bound,
      setBonus: action.bound,
    });
    this.getUser();
    this.getDafaultFavorites();
    this.getDafaultCart();
  }

  setToken = (newToken: string | null) => {
    this.token = newToken;
    if (newToken) {
      localStorage.setItem('token', newToken);
    } else {
      localStorage.removeItem('token');
    }
  };

  setUser = (newUser: User | null) => {
    this.user = newUser;
  };

  setLoading = (newLoading: boolean) => {
    this.loading = newLoading;
  };

  setFavorites = (id: number | null) => {
    if (id) {
      const newFav = this.favorites.includes(id)
        ? this.favorites.filter(f => f !== id)
        : [...this.favorites, id];
      this.favorites = newFav;
      localStorage.setItem('favorites', JSON.stringify(newFav));
    } else {
      localStorage.removeItem('favorites');
    }
  };

  getDafaultFavorites = () => {
    const newFav = localStorage.getItem('favorites');
    if (newFav) {
      this.favorites = JSON.parse(newFav);
    }
  };

  getUser = async () => {
    if (!localStorage.getItem('token')) {
      return;
    }
    const newUser = await api('account');

    if (newUser.error) {
      return;
    }
    this.user = newUser;
  };

  setCart = (newCart: OrderProduct[]) => {
    this.cart = newCart;
    localStorage.setItem('cart', JSON.stringify(newCart));
  };
  setBundlesCart = (newBundlesCart: OrderBundle[]) => {
    this.bundlesCart = newBundlesCart;
    localStorage.setItem('bundlesCart', JSON.stringify(newBundlesCart));
  };
  //
  deleteBundleFromCart = (id: number) => {
    const indexForDel = this.bundlesCart.findIndex(
      item => item.bundle_id === id,
    );
    this.bundlesCart = this.bundlesCart.filter(
      (_, index) => index !== indexForDel,
    );
    localStorage.setItem('bundlesCart', JSON.stringify(this.bundlesCart));
  };

  getDafaultCart = () => {
    const newCart = localStorage.getItem('cart');
    const newBundlesCart = localStorage.getItem('bundlesCart');
    if (newCart) {
      this.cart = JSON.parse(newCart);
    }

    if (newBundlesCart) {
      this.bundlesCart = JSON.parse(newBundlesCart);
    }
  };

  setBonus = (b: number) => {
    this.bonus = b;
  };
}

export default createContext(new UserStore());
