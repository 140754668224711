import useWindowSize from '../../Hocs/useWindowSize';
import HeaderNormal from './HeaderNormal';
import HeaderMobile from './HeaderMobile';

interface AppRouterProps {
 setAuth: (value: boolean) => void;
  auth: boolean; // Replace 'boolean' with the actual type for authentication status if available
}
const Header: React.FC<AppRouterProps>  = ({auth , setAuth}) => {
  //
  const {width} = useWindowSize();

  return <>{width > 640 ? <HeaderNormal auth={auth} setAuth={setAuth}/> : <HeaderMobile auth={auth} setAuth={setAuth}/>}</>;
};

export default Header;
