import 'antd/dist/reset.css';
import '../src/less/index.less';
import {useContext, useEffect} from 'react';
import {Provider} from 'mobx-react';
import {BrowserRouter} from 'react-router-dom';
import {ConfigProvider} from 'antd';
import en from 'antd/lib/locale/en_US';
import {MainLayout} from './components';
import UserStore from './store/UserStore';
import {theme} from './config/theme';
import CommonStore from './store/CommonStore';
import {Category} from './types';
import api from './api/api';
import {gapi} from 'gapi-script';

function App() {
  //
  const storeUser = useContext(UserStore);
  //
  const storeCommon = useContext(CommonStore);

  //
  useEffect(() => {
    (async () => {
      storeUser.setLoading(true);
      const resp: {data: Category[]} = await api(
        'categories?filter[status]=10',
      );
      storeCommon.setCategories(resp.data);
      storeUser.setLoading(false);
    })();
  }, [storeCommon, storeUser]);

  //goole gapi
  gapi.load('client:auth2', () => {
    gapi.client.init({
      clientId: `${process.env.GOOGLE_CLIENT_ID}.apps.googleusercontent.com`,
      scope: '',
    });
  });

  useEffect(() => {
    if (localStorage.getItem('verefy')) {
      localStorage.removeItem('verefy');
    }
  }, []);

  console.log(window.navigator);

  return (
    <Provider storeCommon={storeUser}>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: theme.black,
              colorLink: theme.black,
              linkHoverDecoration: theme.black,
              colorLinkHover: theme.black,
            },
          }}
          locale={en}
          direction={'ltr'}>
          <MainLayout />
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
