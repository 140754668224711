import moment from 'moment';
import 'moment/min/locales.min';
import {Product} from '../types';

// const asCurrency = (value: number, locale: string, currency: string) => {
//   return new Intl.NumberFormat(locale, {
//     style: "currency",
//     currency: currency,
//     maximumFractionDigits: 2,
//     minimumFractionDigits: 0,
//   }).format(value === 0 ? 0 : value);
// };

const asCurrency = (value: number, locale = 'en-US', currency = 'USD') => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  }).format(value === 0 ? 0 : value);
};

const asDate = (date: Date, locale: string, long: boolean = false) => {
  return date.toLocaleDateString(locale, {
    year: 'numeric',
    month: long ? 'long' : '2-digit',
    day: '2-digit',
  });
};

const asDateTime = (date: Date, locale: string) => {
  return date.toLocaleTimeString(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
};

const asTime = (date: Date, locale: string) => {
  return date.toLocaleTimeString(locale, {
    hour: '2-digit',
    minute: '2-digit',
  });
};

const asRelativeTime = (date: Date, locale: string) => {
  moment.locale(locale);
  return moment(date).fromNow();
};

const units = [
  'byte',
  'kilobyte',
  'megabyte',
  'gigabyte',
  'terabyte',
  'petabyte',
];
const asSize = (size: number, locale: string) => {
  let l = 0;
  let n = size;

  while (n >= 1024 && ++l) {
    n = n / 1024;
  }
  return new Intl.NumberFormat(locale, {
    style: 'unit',
    unit: units[l],
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(n);
};

const asPrice = (product: Product) => {
  if (!product.variants) {
    return null;
  }

  let prices: number[] = [];
  product.variants.forEach((size: any) => {
    if (size.variants) {
      size.variants.forEach((diam: any) => {
        prices = [
          ...prices,
          ...(diam?.prices?.filter((p: number | null) => !!p) || []),
        ];
      });
    }
    if (size.prices) {
      prices = [...prices, ...size.prices.filter((p: number | null) => !!p)];
    }
  });

  return asPriceCell(prices);
};

const asPriceCell = (prices: (number | null)[]) => {
  if (!prices) {
    return null;
  }
  if (prices.length === 0) {
    return null;
  }
  if (prices.filter(p => !!p).length === 0) {
    return null;
  }

  const min = Math.min(...(prices as number[]));
  const max = Math.max(...(prices as number[]));

  if (min === max) {
    return asCurrency(max);
  }

  return `${asCurrency(min)}—${asCurrency(max)}`;
};

export {
  asCurrency,
  asDate,
  asDateTime,
  asTime,
  asRelativeTime,
  asSize,
  asPrice,
  asPriceCell,
};
