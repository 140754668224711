import { useTranslation } from 'react-i18next';
import { asCurrency } from '../../utils/formatter';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  CartIcon,
  FavoriteInActiveIcon,
  NotificationInActiveIcon,
  ProfileIcon,
  SearchIcon,
} from '../CastomIcons';
import { Avatar, Badge, Button, Input, Popover, message } from 'antd';
import LogoIcon from '../../UI/LogoIcons';
import { createElement, useContext, useEffect, useRef, useState } from 'react';
import { Observer } from 'mobx-react';
import { RegisterComponent, SocLoginBtns } from '..';
import UserStore from '../../store/UserStore';
import { Config } from '../../config';
import { GoogleOAuthProvider, googleLogout } from '@react-oauth/google';
import { autorun } from 'mobx';
import api from '../../api/api';
import CategoriesComponent from './CategoriesComponent';
import classNames from 'classnames';

const headerLinks = [
  {
    id: 'forum',
    link: '/forum',
    title: 'Forum',
  },
  {
    id: 'contacts',
    link: '/contact-us',
    title: 'Contacts',
  },
  {
    id: 'faq',
    link: '/faq',
    title: 'FAQ',
  },
] as {
  id: string;
  link: string;
  title: string;
}[];
interface HeaderNormal {
  setAuth: (value: boolean) => void;
  auth: boolean; // Replace 'boolean' with the actual type for authentication status if available
}
const HeaderNormal: React.FC<HeaderNormal> = ({ auth, setAuth }) => {
  //
  const { t } = useTranslation();
  //
  const navigate = useNavigate();
  //
  const storeUser = useContext(UserStore);
  const [searchParams] = useSearchParams();
  //
  const [registerVisible, setRegisterVisible] = useState<
    'login' | 'signUp' | 'rememberPass' | null
  >(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [search, setSearch] = useState(searchParams.get('q') || '');

  const timer = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if(auth){setPopoverOpen(true)} 
    if(popoverOpen === false){
      setAuth(false)
    }
  }, [auth, popoverOpen])

  const popoverProfile = (
    <Observer>
      {() => (
        <>
          {!storeUser.user ? (
            <div className='d-popoverProfile__inner'>
              <GoogleOAuthProvider clientId={Config.googleId}>
                <SocLoginBtns />
              </GoogleOAuthProvider>
              <div className='d-login__divider'>or with email</div>
              <Button
                onClick={() => {
                  setRegisterVisible('signUp');
                  setPopoverOpen(false);
                  setAuth(false)
                }}
                type='link'
                className='d-popoverProfile__signUpBtn d-modal__btn'>
                {t('Sign Up')}
              </Button>
              <Button
                onClick={() => {
                  setRegisterVisible('login');
                  setPopoverOpen(false);
                  setAuth(false)
                }}
                type='link'
                className='d-popoverProfile__loginBtn d-modal__btn'>
                {t('Login')}
              </Button>
            </div>
          ) : (
            <>
              <div className='d-popoverProfile__inner'>
                {Config.profileData
                  .filter(f => f.id !== 'notifications')
                  .map(item => (
                    <Button
                      className='d-popoverProfile__inner-profileBtn'
                      type='link'
                      onClick={() => {
                        if (item.id !== 'signOut') {
                          navigate(item.link);
                          setPopoverOpen(false);
                          googleLogout();
                        } else {
                          storeUser.setToken(null);
                          storeUser.setFavorites(null);
                          storeUser.setCart([]);
                          storeUser.setUser(null);
                          setPopoverOpen(false);
                          navigate('/');
                        }
                      }}>
                      <span>{item.title}</span>
                      {item.icon && <>{createElement(item.icon)}</>}
                    </Button>
                  ))}
              </div>
            </>
          )}
        </>
      )}
    </Observer>
  );
  //
  const error = () => {
    messageApi.open({
      type: 'error',
      content: t('Search field is empty'),
    });
  };
  //
  const searchEmpty = () => {
    if (!search.length) {
      error();
    } else {
      navigate(`/search?q=${search}`);
    }
  };

  useEffect(() => {
    autorun(() => {
      if (storeUser.user) {
        if (!timer.current) {
          timer.current = setInterval(async () => {
            const user = await api('account?fields=credits,new_messages');
            if (user) {
              storeUser.setUser({ ...storeUser.user, ...user });
            }
          }, 10 * 1000);
        }
      } else {
        if (timer.current) {
          clearInterval(timer.current);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {contextHolder}
      <div className='d-header'>
        <div className='d-header__header'>
          <Observer>
            {() => (
              <>
                {storeUser.user?.type && (
                  <div className='d-header__header-info'>
                    <span className='d-header__header-sudTitle'>{`${t(
                      'Your level',
                    )}: `}</span>
                    <span className='d-header__header-title'>
                      {t(Config.yourLevel[storeUser.user?.type].label)}
                    </span>
                  </div>
                )}
                {!!storeUser.user?.credits && (
                  <div className='d-header__header-credits'>
                    <span className='d-header__header-sudTitle'>{`${t(
                      'Credits',
                    )}: `}</span>
                    <span className='d-header__header-title'>
                      {asCurrency(storeUser.user?.credits)}
                    </span>
                  </div>
                )}
              </>
            )}
          </Observer>
        </div>
        <div className='d-wrapper--header'>
          <div className='d-header__footer'>
            <div className='d-header__footer-links'>
              <Link className='link-icon' to='/'>
                <LogoIcon />
              </Link>
              {headerLinks.map(link => (
                <Link
                  key={link.id}
                  to={link.link}
                  className={classNames('d-header__footer-link', {
                    contacts: link.id === 'contacts',
                    faq: link.id === 'faq',
                  })}>
                  {t(link.title)}
                </Link>
              ))}
            </div>
            <div className='d-header__footer-search'>
              <Button type='text' onClick={searchEmpty}>
                <SearchIcon />
              </Button>
              <Input
                placeholder={t('Search').toString()}
                className='input'
                allowClear
                onChange={value => {
                  setSearch(value.target.value);
                }}
                onPressEnter={searchEmpty}
              />
            </div>
            <div className='d-header__footer-btns'>
              <Observer>
                {() => (
                  <div className='favorite'>
                    {storeUser.user && storeUser.favorites.length ? (
                      <Link to='/favorite'>
                        <Badge count={storeUser.favorites.length || 0}>
                          <FavoriteInActiveIcon />
                        </Badge>
                      </Link>
                    ) : (
                      <FavoriteInActiveIcon />
                    )}
                  </div>
                )}
              </Observer>
              <Observer>
                {() => (
                  <>
                    {!!storeUser.user && (
                      <div className='favorite'>
                        <Link to='/notifications'>
                          <Badge count={storeUser.user.new_messages}>
                            <NotificationInActiveIcon />
                          </Badge>
                        </Link>
                      </div>
                    )}
                  </>
                )}
              </Observer>
              <Popover
                content={popoverProfile}
                trigger='click'
                arrow={false}
                placement='bottomLeft'
                open={popoverOpen}
                onOpenChange={setPopoverOpen}>
                <div className='profile'>
                  <Observer>
                    {() => (
                      <>
                        {storeUser.user ? (
                          <Avatar icon={false}>
                            {(storeUser.user.fname?.[0] || 'f') +
                              (storeUser.user.lname?.[0] || 'l')}
                          </Avatar>
                        ) : (
                          <ProfileIcon />
                        )}
                      </>
                    )}
                  </Observer>
                </div>
              </Popover>
              <Observer>
                {() => (
                  <>
                    <div className='cart'>
                      <Link to='/cart'>
                        <Badge
                          count={
                            storeUser.cart.length &&
                              storeUser.bundlesCart.length
                              ? storeUser.cart.length +
                              storeUser.bundlesCart.length
                              : storeUser.cart.length
                                ? storeUser.cart.length
                                : storeUser.bundlesCart.length
                          }>
                          <CartIcon />
                        </Badge>
                      </Link>
                    </div>
                  </>
                )}
              </Observer>
            </div>
          </div>
        </div>
        <Observer>
          {() => {
            if (storeUser.user) {
              setRegisterVisible(null);
              return null;
            }

            return (
              <RegisterComponent
                visible={registerVisible}
                setVisible={setRegisterVisible}
              />
            );
          }}
        </Observer>
      </div>
      <CategoriesComponent />
    </>
  );
};

export default HeaderNormal;
