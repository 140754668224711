import {Button, Form, Input} from 'antd';
import {useTranslation} from 'react-i18next';
import {Dispatch, SetStateAction, useContext, useState} from 'react';
import api from '../../../api/api';
import UserStore from '../../../store/UserStore';
import {User, ValidationError} from '../../../types';
import {formErrors} from '../../../utils/formErrors';
import SocLoginBtns from '../../SocLoginBtns';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {Config} from '../../../config';

const {Item} = Form;

interface LoginProps {
  visible: 'login' | 'signUp' | 'rememberPass' | null;
  setVisible: Dispatch<
    SetStateAction<'login' | 'signUp' | 'rememberPass' | null>
  >;
}

const Login = ({visible, setVisible}: LoginProps) => {
  //
  const {t} = useTranslation();
  //
  const storeUser = useContext(UserStore);
  //
  const [form] = Form.useForm();
  //
  const [loading, setLoading] = useState(false);

  const login = async (loginValue: {email: string; password: string}) => {
    setLoading(true);

    const resp: {error?: ValidationError[]; token: string; user: User} =
      await api('api/login', 'POST', loginValue);
    setLoading(false);

    if (resp.error) {
      formErrors(form, resp.error);
      return;
    }

    if (resp.user) {
      localStorage.setItem('token', resp.token);
      storeUser.setToken(resp.token);
      storeUser.setUser(resp.user);
      setVisible(null);
    }
  };

  //
  return (
    <div className='d-login'>
      <h2 className='d-login__title d-modal__title'>{t('Wellcome to Dent')}</h2>
      <div className='d-login__desc d-modal__desc'>
        {t(
          'Consectetur adipiscing elit. Ultricies nisl mattis non mauris ullamcorper.',
        )}
      </div>
      <GoogleOAuthProvider clientId={Config.googleId}>
        <SocLoginBtns />
      </GoogleOAuthProvider>
      <div className='d-login__divider'>{t('or with email')}</div>
      <Form onFinish={login} form={form}>
        <Item
          rules={[{required: true}, {type: 'email'}]}
          name={'email'}
          className='d-login__input d-input'>
          <Input type='email' placeholder='E-mail' />
        </Item>
        <Item
          name={'password'}
          rules={[{required: true}]}
          className='d-login__inputPass d-inputPassword'>
          <Input.Password placeholder='Password' />
        </Item>
        <Button
          type='link'
          onClick={() => {
            setVisible('rememberPass');
          }}
          className='d-login__rememberBtn'>
          {t('Forgot your password?')}
        </Button>
        <Button
          loading={loading}
          type='primary'
          className='d-login__btn d-modal__btn'
          htmlType='submit'>
          {t('Get Started')}
        </Button>
      </Form>
      <div className='d-login__signUp'>
        <div className='d-login__signUp-text'>{t('Need an account?')}</div>
        <Button
          onClick={() => {
            setVisible('signUp');
          }}
          className='d-login__signUp-href'
          type='primary'>
          {t('Sign up here')}
        </Button>
      </div>
    </div>
  );
};

export default Login;
