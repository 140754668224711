import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Config} from '../../config';
import {createElement, useContext, useState} from 'react';
import classNames from 'classnames';
import UserStore from '../../store/UserStore';
import {CollapseIcon} from '../CastomIcons';
import {googleLogout} from '@react-oauth/google';

const MenuMobile = () => {
  //
  const storeUser = useContext(UserStore);
  //
  const location = useLocation();
  //
  const navigate = useNavigate();
  //
  const curentLocation = location.pathname.slice(1);
  //
  const [open, setOpen] = useState(false);

  return (
    <div className='d-menu__mobile'>
      <div className='d-menu__mobile-curent'>
        {Config.profileData
          ?.filter(curent => curent.id === curentLocation)
          ?.map(item => (
            <div
              onClick={() => {
                setOpen(o => !o);
              }}
              id={item.id}
              className='curent'>
              <span className='title'>{item.title}</span>
              <div className='curent-icons'>
                {createElement(item.icon as any)}
                <div
                  className={classNames('curent-arrow', {
                    active: open,
                  })}>
                  <CollapseIcon />
                </div>
              </div>
            </div>
          ))}
      </div>
      <div
        className={classNames('d-menu__mobile-items', {
          active: open,
        })}>
        {Config.profileData
          ?.filter(curent => curent.id !== curentLocation)
          ?.map(item => {
            return item.id !== 'signOut' ? (
              <Link id={item.id} to={item.link} className='d-menu__mobile-item'>
                <span
                  className={classNames('title', {
                    signOut: item.id === 'signOut',
                  })}>
                  {item.title}
                </span>
                <>{item.id !== 'signOut' && createElement(item.icon as any)}</>
              </Link>
            ) : (
              <div
                className='d-menu__mobile-item'
                onClick={() => {
                  googleLogout();
                  storeUser.setToken(null);
                  storeUser.setFavorites(null);
                  storeUser.setCart([]);
                  storeUser.setUser(null);
                  navigate('/');
                }}>
                <span
                  className={classNames('title', {
                    signOut: item.id === 'signOut',
                  })}>
                  {item.title}
                </span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MenuMobile;
