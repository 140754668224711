import {Button, Checkbox, Form, Input, notification} from 'antd';
import {Dispatch, SetStateAction, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ValidationError} from '../../../types';
import api from '../../../api/api';
import {formErrors} from '../../../utils/formErrors';
import {FacebookIcon, GmailIcon, TwitterIcon} from '../../CastomIcons';
import RememberPass from '../RememberPass';
import {GoogleOAuthProvider} from '@react-oauth/google';
import SocLoginBtns from '../../SocLoginBtns';
import {Config} from '../../../config';

const {Item} = Form;

//
interface SignUpProps {
  visible: 'login' | 'signUp' | 'rememberPass' | null;
  setVisible: Dispatch<
    SetStateAction<'login' | 'signUp' | 'rememberPass' | null>
  >;
}

const SignUp = ({visible, setVisible}: SignUpProps) => {
  //
  const {t} = useTranslation();
  // //
  const [form] = Form.useForm();
  //
  const [loading, setLoading] = useState(false);

  //
  const registerUser = async (fieldsValue: {
    email: string;
    password: string;
  }) => {
    setLoading(true);
    const resp: {code: string; error?: ValidationError[]} = await api(
      'api/sign-up',
      'POST',
      fieldsValue,
    );

    setLoading(false);
    if (resp.error) {
      formErrors(
        form,
        resp.error.map(e => ({
          ...e,
          field: ['email', e.message],
        })),
      );
      notification.error({
        message: resp.error[0].message,
      });
      return;
    }

    if (resp.code) {
      localStorage.setItem(
        'verefy',
        JSON.stringify({email: fieldsValue.email, code: resp.code}),
      );
      setVisible('rememberPass');
    }
  };

  return (
    <>
      <div className='d-signUp'>
        <h2 className='d-signUp__title d-modal__title'>
          {t('Let’s Get Started!')}
        </h2>
        <div className='d-signUp__desc d-modal__desc'>
          {t(
            'Consectetur adipiscing elit. Ultricies  nisl mattis non mauris ullamcorper.',
          )}
        </div>
        <div className='d-login__socialBtns'>
          <GoogleOAuthProvider clientId={Config.googleId}>
            <SocLoginBtns />
          </GoogleOAuthProvider>
        </div>
        <div className='d-login__divider'>{t('or with email')}</div>
        <Form onFinish={registerUser} form={form}>
          <Item
            rules={[{required: true}, {type: 'email'}]}
            name={'email'}
            className='d-signUp__input d-input'>
            <Input placeholder={t('E-mail').toString()} type='email' />
          </Item>
          <Item
            name={'password'}
            rules={[{required: true}]}
            className='d-signUp__inputPass d-inputPassword'>
            <Input.Password placeholder={t('Password').toString()} />
          </Item>
          <Item
            valuePropName={'checked'}
            name={'checked'}
            rules={[
              {
                required: true,
                message: t(
                  'You should agree to the Terms of Service and Privacy Policy',
                ).toString(),
              },
            ]}>
            <Checkbox className='d-signUp__checkbox'>
              {t(
                'By Signing up, you agree to the Terms of Service and Privacy Policy',
              )}
            </Checkbox>
          </Item>
          <Button
            type='primary'
            className='d-signUp__btn d-modal__btn'
            htmlType='submit'
            loading={loading}
            // onClick={loginAction}
          >
            {t('Continue')}
          </Button>
        </Form>
        <div className='d-login__signUp'>
          <div className='d-login__signUp-text'>
            {t('Already have an account?')}
          </div>
          <Button
            onClick={() => {
              setVisible('login');
            }}
            className='d-login__signUp-href'
            type='primary'>
            {t('Login')}
          </Button>
        </div>
      </div>
      {visible === 'rememberPass' && (
        <RememberPass visible={visible} setVisible={setVisible} />
      )}
    </>
  );
};

export default SignUp;
