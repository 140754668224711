import {Modal} from 'antd';
import {Dispatch, SetStateAction} from 'react';
import Login from './Login/';
import SignUp from './SignUp';
import RememberPass from './RememberPass';

interface RegisterComponentProps {
  visible: 'login' | 'signUp' | 'rememberPass' | null;
  setVisible: Dispatch<
    SetStateAction<'login' | 'signUp' | 'rememberPass' | null>
  >;
}

const RegisterComponent = ({visible, setVisible}: RegisterComponentProps) => {
  //
  return (
    <>
      <Modal
        className='d-login__form d-modal'
        destroyOnClose
        width={490}
        footer={false}
        open={!!visible}
        onCancel={() => {
          setVisible(null);
        }}>
        {visible === 'login' && (
          <Login visible={visible} setVisible={setVisible} />
        )}
        {visible === 'signUp' && (
          <SignUp visible={visible} setVisible={setVisible} />
        )}
        {visible === 'rememberPass' && (
          <RememberPass visible={visible} setVisible={setVisible} />
        )}
      </Modal>
    </>
  );
};

export default RegisterComponent;
