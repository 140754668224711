import {useTranslation} from 'react-i18next';
import {FAQ} from '../../types';
import {Col, Collapse, Row} from 'antd';
import {CollapseIcon} from '../CastomIcons';

interface FaqProps {
  faqs: FAQ[];
}

const Faq = ({faqs}: FaqProps) => {
  //
  const {t} = useTranslation();

  return (
    <div className='d-home__faq-wrapper'>
      <h1 className='title'>{t('FAQ')}</h1>
      <Row gutter={[30, {xl: 30, md: 0}]}>
        <Col md={24} xl={12}>
          {faqs
            .filter((_, i) => i % 2 === 0)
            .map(faq => (
              <Collapse
                key={faq.id}
                expandIcon={() => (
                  <div className={'collapse-icon'}>
                    <CollapseIcon />
                  </div>
                )}
                expandIconPosition='end'
                className='d-home__faq-item'
                items={[
                  {
                    key: faq.id,
                    label: (
                      <span className='accordion-title'>{faq.question}</span>
                    ),
                    children: (
                      <div
                        dangerouslySetInnerHTML={{__html: faq.answer || ''}}
                        className='desc'
                      />
                    ),
                  },
                ]}
                accordion
              />
            ))}
        </Col>
        <Col md={24} xl={12}>
          {faqs
            .filter((_, i) => i % 2 !== 0)
            .map(faq => (
              <Collapse
                key={faq.id}
                expandIcon={() => (
                  <div className={'collapse-icon'}>
                    <CollapseIcon />
                  </div>
                )}
                expandIconPosition='end'
                className='d-home__faq-item'
                items={[
                  {
                    key: faq.id,
                    label: (
                      <span className='accordion-title'>{faq.question}</span>
                    ),
                    children: (
                      <div
                        dangerouslySetInnerHTML={{__html: faq.answer || ''}}
                        className='desc'
                      />
                    ),
                  },
                ]}
                accordion
              />
            ))}
        </Col>
      </Row>
    </div>
  );
};

export default Faq;
