import {createContext} from 'react';
import {action, observable, makeObservable} from 'mobx';
import {Category, OrderProduct} from '../types';

class CommonStore {
  constructor() {
    makeObservable(this, {
      categories: observable,
      setCategories: action.bound,
    });
  }

  categories: Category[] = [];

  setCategories = (newCats: Category[]) => {
    this.categories = newCats;
  };
}

export default createContext(new CommonStore());
