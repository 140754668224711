import {FormInstance} from 'antd';
import {ValidationError} from '../types';

export const formErrors = (form: FormInstance, errors: ValidationError[]) => {
  errors.forEach(item => {
    form.setFields([
      {
        name: item.field,
        errors: [item.message],
      },
    ]);
  });
};
