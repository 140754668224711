import {Link, useLocation} from 'react-router-dom';
import {Category} from '../../types';
import {Image, Skeleton} from 'antd';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import {Config} from '../../config';

interface CategoriesItemProps {
  item: Category;
  parentCat?: Category;
}

const CategoriesItem = ({item, parentCat}: CategoriesItemProps) => {
  // console.log(item.slug, "iiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii-----------")
  const {t} = useTranslation();
  const location = useLocation();
  const route = location.pathname.split('/')[2];
const thirdslace = location.pathname.split("/")[3]
  return (
    <Link
      style={
        {
          // background: route.slice(-1) === item.id.toString() ? 'red' : 'green',
        }
      }
      to={`${parentCat ? thirdslace ? `/category/${route}` : location.pathname : '/category'}/${item.slug}`}
      // ${subCat?.id ? `/${subCat.id}` : ''}
      className={classNames('d-categoriesItem', {
        active: thirdslace ? thirdslace === item?.slug || item?.slug === route : route === item?.slug,
      })}>
      {!!item.promotion_image || !!item.header_image ? (
        <Image
          preview={false}
          className='d-categoriesItem__avatar'
          src={
            item.promotion_image
              ? item.promotion_image
              : item.header_image || ''
          }
          fallback={Config.fallbackImage}
        />
      ) : (
        <Skeleton.Avatar active={true} className='d-categoriesItem__avatar' />
      )}
      <span className='d-categoriesItem__title'>{item.title}</span>
      <span className='d-categoriesItem__desc'>{`${t('All')} ${
        item.productsCount
      }`}</span>
    </Link>
  );
};

export default CategoriesItem;
