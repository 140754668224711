import {Layout, Spin} from 'antd';
import {Content} from 'antd/lib/layout/layout';
import AppRouter from '../../router';
import {theme} from '../../config/theme';
import {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import Header from '../Header';
import UserStore from '../../store/UserStore';
import {Observer} from 'mobx-react';
import Footer from '../Footer';
import CommonStore from '../../store/CommonStore';

const MainLayout = () => {
  const location = useLocation();
  const [auth, setAuth] = useState(false)
  const storeUser = useContext(UserStore);
  const storeCommon = useContext(CommonStore);

  //
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [location.pathname]);

  return (
    <>
      <Header auth={auth} setAuth={setAuth}/>
      <Observer>
        {() => (
          <Spin
            size='large'
            rootClassName='d-loader-item'
            spinning={storeUser.loading}>
            <Layout
              style={{
                display: 'flex',
                flexDirection: 'row',
                background: theme.white,
                minHeight: '100vh',
              }}>
              <Content
                style={{
                  maxWidth: '100%',
                }}>
                {!!storeCommon.categories.length && <AppRouter setAuth={setAuth} auth={auth} />}
              </Content>
            </Layout>
          </Spin>
        )}
      </Observer>
      <Footer />
    </>
  );
};

export default MainLayout;
