import {createElement, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import UserStore from '../../store/UserStore';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Menu} from 'antd';
import {Config} from '../../config';
import useWindowSize from '../../Hocs/useWindowSize';
import MenuMobile from './MenuMobile';
import {NotificationIcon} from '../CastomIcons';

//
const {Item} = Menu;

const ProfileMenu = () => {
  //
  //
  const {t} = useTranslation();
  //
  const storeUser = useContext(UserStore);
  //
  const {width} = useWindowSize();
  //
  const location = useLocation();
  //
  const navigate = useNavigate();

  const menuItems = (
    id: string,
    link: string,
    title: string,
    icon: (props: any) => JSX.Element,
  ) => {
    return (
      <Item
        id={id}
        onClick={() => {
          if (id === 'signOut') {
            localStorage.removeItem('token');
            storeUser.setToken(null);
            storeUser.setFavorites(null);
            storeUser.setCart([]);
            storeUser.setUser(null);
            navigate('/');
            return false;
          }
        }}
        icon={id !== 'signOut' && createElement(icon as any)}
        key={link}>
        <Link to={link}>{t(title)}</Link>
      </Item>
    );
  };

  return (
    <>
      {width > 990 ? (
        <Menu
          className='d-menu__wrapper'
          id={`menu-${storeUser.user?.id}`}
          mode='inline'
          theme='light'
          defaultSelectedKeys={[location.pathname]}>
          {storeUser.user && (
            <>
              {Config.profileData.map(item => (
                <>{menuItems(item.id, item.link, item.title, item.icon)}</>
              ))}
            </>
          )}
        </Menu>
      ) : (
        <MenuMobile />
      )}
    </>
  );
};

export default ProfileMenu;
