import {useTranslation} from 'react-i18next';
import {Button, Input, Statistic, notification} from 'antd';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import OtpInput from '../../../UI/OtpInput';
import api from '../../../api/api';
import {User} from '../../../types';

//
const {Countdown} = Statistic;

interface RememberPassProps {
  visible: 'login' | 'signUp' | 'rememberPass' | null;
  setVisible: Dispatch<
    SetStateAction<'login' | 'signUp' | 'rememberPass' | null>
  >;
}

const RememberPass = ({visible, setVisible}: RememberPassProps) => {
  //
  const {t} = useTranslation();
  //
  const [userData, setUserData] = useState<{
    email: string;
    code: string;
    pass: string;
  }>({
    email: '',
    code: '',
    pass: '',
  });
  const [countDown, setCountDown] = useState(Date.now() + 30 * 1000);
  const [loading, setLoading] = useState(false);
  const [resetCode, setResetCode] = useState(false);
  const [steps, setSteps] = useState<'email' | 'code' | 'password' | null>(
    'email',
  );
  //
  const signUp = async () => {
    if (!userData.pass) {
      notification.error({
        message: `${t('The entered password')}`,
      });
      return;
    }
    setLoading(true);
    const resp: {token: string; user: User} = await api(
      'api/reset-password',
      'POST',
      {
        email: userData.email,
        pass: userData.pass,
        code: userData.code,
      },
    );
    setLoading(false);

    if (resp) {
      // localStorage.removeItem('verefy');
      // localStorage.setItem('token', resp.token);
      // storeUser.setToken(resp.token);
      // storeUser.setUser(resp.user);
      setVisible(null);
      notification.success({
        message: `${t(
          `Password changed successfully, you can login ${userData.email} in with a new password`,
        )}`,
      });
    }
  };
  //
  const getResetCode = async () => {
    if (!userData.email) {
      notification.error({
        message: `${t('Please enter your email')}`,
      });
      return;
    }
    setLoading(true);
    const resp: {success: boolean; message: string} = await api(
      'api/reset-password',
      'POST',
      {
        email: userData.email,
      },
    );
    setLoading(false);
    if (resp.success) {
      setSteps('code');
      setResetCode(false);
    }
    notification.info({
      message: `${t('Code resent to email')} ${userData.email}`,
    });
    setCountDown(Date.now() + 30 * 1000);
    // setResetCode(true);
  };
  const checkCode = async () => {
    const resp = await api('api/check-code', 'POST', {
      email: userData.email,
      code: userData.code,
    });

    if (resp) {
      setSteps('password');
    }
  };

  useEffect(() => {
    if (visible) {
      const data = localStorage.getItem('verefy');
      if (data) {
        setUserData(JSON.parse(data));
      }
    }
  }, [visible]);

  console.log('userData', userData);

  return (
    <div className='d-login-rememberPass'>
      <div className='d-login__title d-modal__title'>
        {t('Forgot your password?')}
      </div>
      <div className='d-login__desc d-modal__desc'>
        {t('we send you an {{email}} code to reset your password.', {
          email: userData.email,
        })}
      </div>
      {steps === 'email' && (
        <Input
          type='email'
          required={true}
          className='d-login__input d-input__pass'
          placeholder={t('Email').toString()}
          onChange={value => {
            setUserData(state => ({
              ...state,
              email: value.target.value,
            }));
          }}
        />
      )}
      {steps === 'code' && (
        <OtpInput
          onChange={value => {
            setUserData(state => ({
              ...state,
              code: value,
            }));
          }}
          length={6}
        />
      )}
      {steps === 'password' && (
        <Input.Password
          type='password'
          required={true}
          className='d-login__input d-input__pass'
          placeholder={t('Password').toString()}
          onChange={value => {
            setUserData(state => ({
              ...state,
              pass: value.target.value,
            }));
          }}
        />
      )}

      {steps === 'code' && (
        <div className='d-login-rememberPass__timer'>
          <Countdown
            className='d-login-rememberPass__timer'
            value={countDown}
            title={t('resend code')}
            format='mm:ss'
            onFinish={() => {
              setResetCode(true);
            }}
          />
          {resetCode && (
            <Button
              loading={loading}
              type='text'
              onClick={() => {
                getResetCode();
              }}>
              {t('Resend code')}
            </Button>
          )}
        </div>
      )}
      {steps === 'email' && (
        <Button
          loading={loading}
          // disabled={checkCodeState}
          type='primary'
          className='d-login-rememberPass__btn d-modal__btn'
          onClick={getResetCode}>
          {t('Send code')}
        </Button>
      )}
      {steps === 'code' && (
        <Button
          loading={loading}
          // disabled={checkCodeState}
          type='primary'
          className='d-login-rememberPass__btn d-modal__btn'
          onClick={checkCode}>
          {t('Continue')}
        </Button>
      )}
      {steps === 'password' && (
        <Button
          loading={loading}
          // disabled={checkCodeState}
          type='primary'
          className='d-login-rememberPass__btn d-modal__btn'
          onClick={signUp}>
          {t('Сhange password')}
        </Button>
      )}
    </div>
  );
};

export default RememberPass;
