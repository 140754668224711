import {useEffect} from 'react';

interface OtpInputProps {
  length: number;
  onChange?: (value: string) => void;
}

const OtpInput = ({length, onChange}: OtpInputProps) => {
  //
  const handleInput = (e: any) => {
    let inputField = e.target;
    if (inputField.value.length >= 1) {
      let nextField = inputField.nextElementSibling;
      if (nextField) {
        nextField.focus();
      } else {
        const inputFields = document.querySelectorAll('input.field');
        let value = '';
        inputFields.forEach((input: any) => {
          value += input.value.toString();
        });
        if (onChange) {
          onChange(value);
        }
      }
    }
  };

  //
  const handleBackspace = (e: Event) => {
    if ((e as KeyboardEvent).key !== 'Backspace') {
      return;
    }
    if (!(e.target as HTMLInputElement)?.previousElementSibling) {
      return;
    }
    const currentInput = e.target as HTMLInputElement;
    if (currentInput.value) {
      return;
    }
    const prevInput = (e.target as HTMLInputElement)
      .previousElementSibling as HTMLInputElement;
    prevInput.value = '';
    prevInput.focus();
    return;
  };

  //
  useEffect(() => {
    const inputFields = document.querySelectorAll('input.field');

    inputFields.forEach(field => {
      field.addEventListener('input', handleInput);
      field.addEventListener('keydown', handleBackspace);
    });

    (inputFields[0] as HTMLInputElement).focus();

    return () => {
      inputFields.forEach(field => {
        field.removeEventListener('input', handleInput);
        field.removeEventListener('keydown', handleBackspace);
      });
    };
  }, []);

  return (
    <div className='d-otpInput'>
      {new Array(length).fill(0).map((_, index) => (
        <input type='number' className={`field ${index}`} maxLength={1} />
      ))}
    </div>
  );
};

export default OtpInput;
