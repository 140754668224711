import { Button } from 'antd';
import { FacebookIcon, GmailIcon, TwitterIcon } from '../CastomIcons';
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { Config } from '../../config';
import api from '../../api/api';
import { useContext, useState } from 'react';
import UserStore from '../../store/UserStore';
import { User } from '../../types';
import AppleLogin from 'react-apple-login';
import FacebookLogin, {
  ProfileSuccessResponse,
} from '@greatsumini/react-facebook-login';

const SocLoginBtns = () => {
  //
  const storeUser = useContext(UserStore);
  const [show, setShow] = useState(true);

  //
  const responseGoogle = async (
    response: GoogleLoginResponse | GoogleLoginResponseOffline | any
  ) => {
    if (response) {
      const resp: { token: string; user: User } = await api(
        'api/soc-login',
        'POST',
        {
          google_id: response.googleId,
          email: response.profileObj?.email,
          fname: response.profileObj?.givenName,
          avatar: response.profileObj?.imageUrl,
          lname: response.profileObj?.familyName || '',
        }
      );

      if (resp) {
        localStorage.setItem('token', resp.token);
        storeUser.setToken(resp.token);
        storeUser.setUser(resp.user);
      }
    }
  };

  //
  const responseFacebook = async (response: ProfileSuccessResponse) => {
    if (response) {
      const resp: { token: string; user: User } = await api(
        'api/soc-login',
        'POST',
        {
          facebook_id: response.id,
          email: response.email,
          fname: response.name?.split(' ')[0],
          lname: response.name?.split(' ')[1],
        }
      );

      if (resp) {
        localStorage.setItem('token', resp.token);
        storeUser.setToken(resp.token);
        storeUser.setUser(resp.user);
      }
    }
  };

  //
  const loginApple = async (data: {
    authorization: { code: string; id_token: string };
  }) => {
    const resp = await api('api/login-apple-web', 'POST', data.authorization);

    if (resp) {
      localStorage.setItem('token', resp.token);
      storeUser.setToken(resp.token);
      storeUser.setUser(resp.user);
    }
  };

  //
  // useEffect(() => {
  //   setTimeout(() => {
  //     setShow(true);
  //   }, 2 * 1000);
  // }, []);

  return (
    <div className="d-login__socialBtns">
      <GoogleLogin
        clientId={Config.googleId}
        // buttonText='Login'
        render={(renderProps) => (
          <Button
            onClick={renderProps.onClick}
            type="text"
            className="d-login__socialBtns-item"
          >
            <GmailIcon />
          </Button>
        )}
        onSuccess={responseGoogle}
        onFailure={responseGoogle}
        cookiePolicy={'single_host_origin'}
      />

      <FacebookLogin
        appId="346628487641835"
        onSuccess={() => {}}
        onFail={(error) => {
          console.log('Login Failed!', error);
        }}
        onProfileSuccess={responseFacebook}
        render={({ onClick, logout }) => (
          <Button
            onClick={onClick}
            type="text"
            className="d-login__socialBtns-item"
          >
            <FacebookIcon />
          </Button>
        )}
      />

      {show && (
        <AppleLogin
          clientId="app.dental.solutions.web"
          redirectURI="https://ds-implant.com"
          usePopup={true}
          callback={loginApple} // Catch the response
          scope="email name"
          responseMode="form_post"
          render={(
            renderProps //Custom Apple Sign in Button
          ) => (
            <Button
              type="text"
              onClick={renderProps.onClick}
              className="d-login__socialBtns-item"
            >
              <TwitterIcon />
            </Button>
          )}
        />
      )}
    </div>
  );
};

export default SocLoginBtns;
