import React, {useContext} from 'react';
import {Result} from 'antd';
import {lazy, Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';
import CommonStore from '../store/CommonStore';
import {Category} from '../types';

const HomePage = lazy(() => import('../pages/HomePage'));
const CategoryesPages = lazy(() => import('../pages/CategoryesPages'));
const ProductPages = lazy(() => import('../pages/ProductPages'));
const CartPage = lazy(() => import('../pages/CartPage'));
const AccountPage = lazy(() => import('../pages/AccountPage'));
const ShippingPage = lazy(() => import('../pages/ShippingPage'));
const CardsPage = lazy(() => import('../pages/CardsPage'));
const CreditsPage = lazy(() => import('../pages/CreditsPage'));
const OrderPage = lazy(() => import('../pages/OrderPage'));
const NotificationPage = lazy(() => import('../pages/NotificationPage'));
const AboutUsPage = lazy(() => import('../pages/AboutUsPage'));
const FavoritesPage = lazy(() => import('../pages/FavoritesPage'));
const ForumPage = lazy(() => import('../pages/ForumPage'));
const ForumItemPage = lazy(() => import('../pages/ForumItemPage'));
const TermsOfServicePage = lazy(() => import('../pages/TermsOfServicePage'));
const ContactUsPage = lazy(() => import('../pages/ContactUsPage'));
const CheckoutPage = lazy(() => import('../pages/CheckoutPage'));
const FaqPage = lazy(() => import('../pages/FaqPage'));
const SearchPage = lazy(() => import('../pages/SearchPage'));
const PrivacyPage = lazy(() => import('../pages/PrivacyPage'));
const ProductsPage = lazy(() => import('../pages/ProductsPage'));
const BundlePages = lazy(() => import('../pages/BundlePages'));

interface AppProps {
  auth: boolean;
  setAuth: (value: boolean) => void; // Assuming setAuth is a function that takes a boolean argument
}
const AppRouter : React.FC<AppProps>= ({setAuth, auth}) => {
  //
  const storeCommon = useContext(CommonStore);
  const routes: string[] = [];

  const addToRotes = (route: string, cat: Category) => {
    routes.push(route + '/' + cat.slug);
    cat.children.forEach(sub => {
      addToRotes(route + '/' + cat.slug, sub);
    });
  };

  storeCommon.categories.forEach(cat => {
    addToRotes('/category', cat);
  });

  return (
    <Suspense fallback={null}>
      <Routes>
        {routes.map(r => (
          <Route path={r} element={<CategoryesPages />} key={r} />
        ))}
        {routes.map(r => (
          <Route
            path={r + '/:productId'}
            element={<ProductPages />}
            key={r + '/product'}
          />
        ))}
        <Route path='/' element={<HomePage />} />
        <Route path='/cart' element={<CartPage setAuth={setAuth} auth={auth}/>} />
        <Route path='/account' element={<AccountPage />} />
        <Route path='/shipping' element={<ShippingPage />} />
        <Route path='/cards' element={<CardsPage />} />
        <Route path='/credits' element={<CreditsPage />} />
        <Route path='/orders' element={<OrderPage />} />
        <Route path='/notifications' element={<NotificationPage />} />
        <Route path='/about' element={<AboutUsPage />} />
        <Route path='/favorite' element={<FavoritesPage />} />
        <Route path='/forum' element={<ForumPage />} />
        <Route path='/forum/:articliesId' element={<ForumItemPage />} />
        <Route path='/terms' element={<TermsOfServicePage />} />
        <Route path='/privacy' element={<PrivacyPage />} />
        <Route path='/contact-us' element={<ContactUsPage />} />
        <Route path='/checkout' element={<CheckoutPage />} />
        <Route path='/faq' element={<FaqPage />} />
        <Route path='/search' element={<SearchPage />} />
        <Route path='/products/:productFilter' element={<ProductsPage />} />
        <Route path='/bundle/:bundleId' element={<BundlePages />} />
        <Route path='*' element={<Result status='404' title='404' />} />;
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
