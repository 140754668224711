import {Button, Image} from 'antd';
import {Config} from '../../config';
import {CloseIcon} from '../CastomIcons';
import {Bundle} from '../../types';
import {useContext} from 'react';
import UserStore from '../../store/UserStore';
import {asCurrency} from '../../utils/formatter';

interface CartItemProps {
  bundle: Bundle;
}

const CartBundleItem = ({bundle}: CartItemProps) => {
  //
  const storeUser = useContext(UserStore);

  return (
    <div className='d-cart__item'>
      <Image
        preview={false}
        className='d-cart__item-img'
        src={bundle?.images[0]}
        fallback={Config.fallbackImage}
      />
      <div className='d-cart__item-info'>
        <div className='d-cart__item-title'>{bundle?.title}</div>
        {/* <div className='d-cart__item-size'>
          <br />
          <div dangerouslySetInnerHTML={{__html: bundle?.description || ''}} />
        </div> */}
        <div className='d-cart__item-price'>{asCurrency(bundle.price)}</div>
      </div>
      <Button
        onClick={() => {
          storeUser.deleteBundleFromCart(bundle.id);
        }}
        type='primary'
        className='d-cart__item-close'
        icon={<CloseIcon />}></Button>
    </div>
  );
};

export default CartBundleItem;
