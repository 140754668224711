export const theme = {
  black: '#181818',
  red: '#e3000b',
  white: '#ffffff',
  gray: '#f6f6f6',
  darkGray: '#666666',
  yellow: '#feda03',
  sand: '#ffffe8',
  green: '#1CAA50',
  darkBlue: '#1a3c68',
};
