import {useMemo, useState} from 'react';
import LogoIcon from '../../UI/LogoIcons';
import {Category} from '../../types';
import api from '../../api/api';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {
  AmericanExpressIcon,
  MasterCardIcon,
  PayPalIcon,
  VisaIcon,
} from '../CastomIcons';
import {Col, Row} from 'antd';

const infoData = [
  {
    key: 'aboutUs',
    link: '/about',
    title: 'About us',
  },
  {
    key: 'termsOfService',
    link: '/terms',
    title: 'Terms of service',
  },
  {
    key: 'privacyPolicy',
    link: '/privacy',
    title: 'Privacy Policy',
  },
  // {
  //   key: 'returnPolicy',
  //   link: '/returnPolicy',
  //   title: 'Return Policy',
  // },
  {
    key: 'faq',
    link: '/faq',
    title: 'FAQ',
  },
  {
    key: 'contactUs',
    link: '/contact-us',
    title: 'Contact us',
  },
] as {
  key: string;
  link: string;
  title: string;
}[];

const Footer = () => {
  //
  const {t} = useTranslation();
  //
  const [categories, setCategories] = useState<Category[]>([]);
  //
  const getCategory = async () => {
    const respCat = await api('categories?filter[status]=10');

    if (respCat) {
      setCategories(respCat.data);
    }
  };

  useMemo(() => {
    getCategory();
  }, []);

  return (
    <div className='d-footer'>
      <div className='d-wrapper'>
        <Row className='d-footer__items' gutter={[30, 30]}>
          <Col xs={24} xl={12} className='d-footer__logo'>
            <LogoIcon width={220} height={40} />
            <a
              className='tel'
              href='tel:+972547439889'
              target='_blank'
              rel='noreferrer'>
              {'+972 54 743 9889'}
            </a>
            <a
              href='https://goo.gl/maps/ruFMS2K7dyXP63XN6'
              target='_blank'
              className='address'
              rel='noreferrer'>
              {'HaOreg Street 20, Netanya, Israel'}
            </a>
          </Col>
          <Col xs={12} xl={6} className='d-footer__catalog'>
            <div className='d-footer__catalog-title'>{t('Catalog')}</div>
            {categories.map(cat => (
              <Link
                key={cat.id}
                to={`/category/${cat.slug}`}
                className='d-footer__catalog-item'>
                {cat.title}
              </Link>
            ))}
          </Col>
          <Col xs={12} xl={6} className='d-footer__info'>
            <div className='d-footer__catalog-title'>{t('Information')}</div>
            {infoData.map(info => (
              <Link
                key={info.key}
                to={info.link}
                className='d-footer__catalog-item'>
                {info.title}
              </Link>
            ))}
          </Col>
        </Row>
        <div className='d-footer__copyright'>
          Copyright © All rights Reserved
          <div className='d-footer__copyright-cards'>
            <VisaIcon />
            <MasterCardIcon />
            {/* <PayPalIcon /> */}
            <AmericanExpressIcon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
