import { Observer } from 'mobx-react';
import CommonStore from '../../store/CommonStore';
import { useContext } from 'react';
import CategoriesItem from '../../UI/CategoriesItem';
import { FreeMode, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';

const CategoriesComponent = () => {
  //
  const storeCommon = useContext(CommonStore);
  //

  return (
    <div className='d-home__categories'>
      <Observer>
        {() => (
          <div className='d-wrapper'>
            <>
              {!!storeCommon.categories.length && (
                <Swiper
                  autoplay={{
                    delay: 500,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    300: {
                      slidesPerView: 3.8,
                      spaceBetween: 20,
                      slidesOffsetAfter: 16,
                      slidesOffsetBefore: 16,
                    },
                    640: {
                      slidesPerView: 4.3,
                      spaceBetween: 20,
                      slidesOffsetAfter: 0,
                      slidesOffsetBefore: 0,
                    },
                    820: {
                      slidesPerView: 4.5,
                      spaceBetween: 20,
                    },
                    1280: {
                      slidesPerView: storeCommon.categories.length,
                      spaceBetween: 20,
                    },
                  }}
                  // className='d-home__banner-slider-wrapper'
                  key={new Date().getDate()}
                  // loop={true}
                  slidesPerView={6}
                  resistance={true}
                  resistanceRatio={0}
                  watchSlidesProgress={true}
                  modules={[FreeMode, Scrollbar]}>
                  {storeCommon.categories.map(cat => {
                    // console.log(cat.slug, "header cattttttttttt")
                    return <SwiperSlide key={cat.id}>
                      <CategoriesItem item={cat} />
                    </SwiperSlide>
                  }

                  )}
                </Swiper>
              )}
            </>
          </div>
        )}
      </Observer>
    </div>
  );
};

export default CategoriesComponent;
