import { Avatar, Badge, Button, Col, Drawer, Input, Row, message } from 'antd';
import {
  BurgerIcon,
  CartIcon,
  CloseIcon,
  FavoriteActiveIcon,
  FavoriteInActiveIcon,
  NotificationInActiveIcon,
  ProfileIcon,
  SearchIcon,
} from '../CastomIcons';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import LogoIcon from '../../UI/LogoIcons';
import { Observer } from 'mobx-react';
import UserStore from '../../store/UserStore';
import RegisterComponent from '../RegisterComponent';
import { useTranslation } from 'react-i18next';
import CategoriesItem from '../../UI/CategoriesItem';
import CommonStore from '../../store/CommonStore';
import { Config } from '../../config';
import { asCurrency } from '../../utils/formatter';

interface HeaderMobileProps {
  setAuth: (value: boolean) => void;
  auth: boolean; // Replace 'boolean' with the actual type for authentication status if available
}

const headerLinks = [
  {
    id: 'forum',
    link: '/forum',
    title: 'Forum',
  },
  {
    id: 'contacts',
    link: '/contact-us',
    title: 'Contacts',
  },
  {
    id: 'faq',
    link: '/faq',
    title: 'FAQ',
  },
] as {
  id: string;
  link: string;
  title: string;
}[];

const HeaderMobile: React.FC<HeaderMobileProps> = ({ auth, setAuth }) => {
  //
  const storeUser = useContext(UserStore);
  const storeCommon = useContext(CommonStore);
  //
  const [searchParams] = useSearchParams();
  //
  //
  const { t } = useTranslation();
  //
  const navigate = useNavigate();
  //
  const [burgerOpen, setBurgerOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  //
  const [registerVisible, setRegisterVisible] = useState<
    'login' | 'signUp' | 'rememberPass' | null
  >(null);
  //
  const [search, setSearch] = useState(searchParams.get('q') || '');
  //
  const error = () => {
    messageApi.open({
      type: 'error',
      content: t('Search field is empty'),
    });
  };
  useEffect(() => {
    if (registerVisible == null) {
      setAuth(false)
    }
  }, [registerVisible])
  const searchEmpty = () => {
    if (!search.length) {
      error();
    } else {
      navigate(`/search?q=${search}`);
      setSearchOpen(false);
    }
  };
  useEffect(() => {
    if (auth) {
      setRegisterVisible("login")
    }
  }, [auth])
  return (
    <>
      {contextHolder}
      <Observer>
        {() => (
          <>
            {(storeUser.user?.type || storeUser.user?.credits) && (
              <div className='d-header__header'>
                {storeUser.user?.type && (
                  <div className='d-header__header-info'>
                    <span className='d-header__header-sudTitle'>{`${t(
                      'Your level',
                    )}: `}</span>
                    <span className='d-header__header-title'>
                      {t(Config.yourLevel[storeUser.user?.type].label)}
                    </span>
                  </div>
                )}
                {!!storeUser.user?.credits && (
                  <div className='d-header__header-credits'>
                    <span className='d-header__header-sudTitle'>{`${t(
                      'Credits',
                    )}: `}</span>
                    <span className='d-header__header-title'>
                      {asCurrency(storeUser.user?.credits)}
                    </span>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </Observer>
      <div className='d-header__mobile'>
        <div className='d-wrapper'>
          <Row style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Col span={2}>
              <div
                onClick={() => {
                  setBurgerOpen(b => !b);
                }}
                className='d-header__mobile-burger'>
                <BurgerIcon />
              </div>
            </Col>

            <Col style={{ display: 'flex', justifyContent: 'center' }} span={10}>
              <Link className='link-icon' to='/'>
                <LogoIcon />
              </Link>
            </Col>
            <Col span={2}>
              <Button
                onClick={() => {
                  setSearchOpen(s => !s);
                }}
                className='d-header__mobile-search'
                type='text'>
                <SearchIcon />
              </Button>
            </Col>
            <Col span={2}>
              <Observer>
                {() => (
                  <>
                    <div className='cart'>
                      <Link to='/cart'>
                        <Badge count={storeUser.cart.length}>
                          <CartIcon />
                        </Badge>
                      </Link>
                    </div>
                  </>
                )}
              </Observer>
            </Col>
            {/* <Col span={2}>
              <Observer>
                {() => (
                  <div className='favorite'>
                    {!!storeUser.favorites.length ? (
                      <Link to='/favorite'>
                        <Badge count={storeUser.favorites.length || 0}>
                          <FavoriteActiveIcon />
                        </Badge>
                      </Link>
                    ) : (
                      <FavoriteInActiveIcon />
                    )}
                  </div>
                )}
              </Observer>
            </Col> */}
          </Row>
        </div>
      </div>
      <Observer>
        {() => {
          if (storeUser.user) {
            setRegisterVisible(null);
            return null;
          }
          return (
            <RegisterComponent

              visible={registerVisible}
              setVisible={setRegisterVisible}
            />
          );
        }}
      </Observer>
      <Drawer
        placement='top'
        width={'100%'}
        height={150}
        closeIcon={<CloseIcon />}
        open={searchOpen}
        onClose={() => {
          setSearchOpen(false);
        }}>
        <div className='d-header__footer-search mobile'>
          <Button type='text' onClick={searchEmpty}>
            <SearchIcon />
          </Button>
          <Input
            placeholder={t('Search').toString()}
            className='input'
            allowClear
            onChange={value => {
              setSearch(value.target.value);
            }}
            onPressEnter={searchEmpty}
          />
        </div>
      </Drawer>
      <Drawer
        closeIcon={<CloseIcon />}
        className='d-burder__drawer'
        rootClassName='d-burder__drawer root'
        width={'100%'}
        open={burgerOpen}
        onClose={() => {
          setBurgerOpen(false);
        }}>
        <div className='d-drawer__header'>
          <Observer>
            {() => (
              <>
                <div className='favorite'>
                  {!!storeUser.favorites.length ? (
                    <Link
                      onClick={() => {
                        setBurgerOpen(false);
                      }}
                      to='/favorite'>
                      <Badge count={storeUser.favorites.length || 0}>
                        <FavoriteActiveIcon />
                      </Badge>
                    </Link>
                  ) : (
                    <FavoriteInActiveIcon />
                  )}
                </div>
                {!!storeUser.user && (
                  <div className='favorite'>
                    <Link
                      onClick={() => {
                        setBurgerOpen(false);
                      }}
                      to='/notifications'>
                      <NotificationInActiveIcon />
                    </Link>
                  </div>
                )}
                {storeUser.user ? (
                  <Link
                    onClick={() => {
                      setBurgerOpen(false);
                    }}
                    to={'/account'}>
                    <Avatar icon={false}>
                      {(storeUser.user.fname?.[0] || 'f') +
                        (storeUser.user.lname?.[0] || 'l')}
                    </Avatar>
                  </Link>
                ) : (
                  <Button
                    type='text'
                    onClick={() => {
                      setRegisterVisible('signUp');
                      setBurgerOpen(false);
                    }}>
                    <ProfileIcon />
                  </Button>
                )}
              </>
            )}
          </Observer>
        </div>
        <div className='d-drawer__links'>
          {headerLinks.map(link => (
            <Link
              onClick={() => {
                setBurgerOpen(false);
              }}
              key={link.id}
              to={link.link}
              className='d-header__footer-link'>
              {t(link.title)}
            </Link>
          ))}
        </div>
        <Row gutter={[16, 16]}>
          {storeCommon.categories.map(cat => (
            <Col
              onClick={() => {
                setBurgerOpen(false);
              }}
              span={12}>
              <CategoriesItem item={cat} />
            </Col>
          ))}
        </Row>
      </Drawer>
    </>
  );
};

export default HeaderMobile;
